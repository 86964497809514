/* === Import Bootstrap functions and variables === */
@import "bootstrap/scss/functions";
//@import "bootstrap/scss/variables"; // нарушает цвета!!!
/*--------------------------------------------------------------------*/
/* Change theme-colors */

/* Нужен для белого цвета текста на кнопках */
$min-contrast-ratio: 2;

$primary :  #78b01b; /*  #1dbf56; */
$danger :  red; /*  #1dbf56; */

$theme-colors: (
   "primary":    $primary,
  // "secondary":  $secondary,
  // "success":    $success,
  // "info":       $info,
  // "warning":    $warning,
   "danger":     $danger,
  // "light":      $light,
  // "dark":       $dark
);

/*--------------------------------------------------------------------*/
/* === Bootstrap Main SCSS === */
//@import "bootstrap/scss/bootstrap";
@import "./bootstrap"; // оптимизированный
/*--------------------------------------------------------------------*/
#root
{
	max-width: 720px;
}

.header
{
	display: flex !important;
	margin-bottom: 1rem;
	align-items: center;
	padding: 0.3rem;

	img
	{
		height: 2em;
		margin-right: 0.2rem;
	}
	h1
	{
		margin: 0;
	}
}

.state-image
{
	display: inline-block;
	width: 1.3em;
	height: 1.3em;
	vertical-align: text-top;
	margin-right: 5px;
	vertical-align: text-bottom;
}
.state-image-ok
{
 background-color: rgb(0, 158, 0);
 mask: url('../public/static/images/check-circle-fill.svg') no-repeat center;
 -webkit-mask: url('../public/static/images/check-circle-fill.svg') no-repeat center;
 mask-size: cover;
 -webkit-mask-size: cover;
}
.state-image-alert
{
  background-color: red;
  mask: url('../public/static/images/exclamation-circle-fill.svg') no-repeat center;
  -webkit-mask: url('../public/static/images/exclamation-circle-fill.svg') no-repeat center;
  mask-size: cover;
 -webkit-mask-size: cover;
}